import React from "react"
import { Container, Row, Col } from "styled-bootstrap-grid"
import { StyledDefaultPage } from "../templates/Page/styles"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Container>
      <Row>
        <Col col={12}>
          <StyledDefaultPage>
            <h1>404: Not Found</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          </StyledDefaultPage>
        </Col>
      </Row>
    </Container>
  </Layout>

)

export default NotFoundPage
