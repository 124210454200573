import styled from 'styled-components'
import { media } from "styled-bootstrap-grid"

export const StyledDefaultPage = styled.div`
  padding: 32px 0 64px;
  h1 {
    font-size: 42px;
    ${media.md`
      font-size: 50px;
    `}
  }
`